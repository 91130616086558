.home--common-information-section{
  padding: rem(57px 0 19.4vw 0);
  position: relative;
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  font-size: rem(18px);
  line-height: 1.5;
  color: cl(text);
  overflow: hidden;
  background-attachment: fixed;
  @include media-breakpoint-down(md) {
    background-size: 100% auto;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-attachment:scroll;
  }
  &:before{
    content: "";
    position: absolute;
    left: 0;
    height: 40%;
    width: 100%;
    top: 0;
    z-index: 0;
    background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 30%, rgba(255,255,255,0) 100%);
  }
  .container{
    max-width: rem(860px);
  }
  .welcome__slogan {
    position: absolute;
    bottom: 1vw;
    left:0;
    width:100%;
    text-shadow: rem(0 9px 40px cl(black, .21));
    color:cl(white);
    text-align: center;
    margin: 0;
    font-size: 10.4vw;
    line-height: 1;
    font-family: $fontTitle;
    white-space: nowrap;
    text-transform: uppercase;
    //@include media-breakpoint-down(lg) {
    //  font-size: rem(150px);
    //}
    //@include media-breakpoint-down(md) {
    //  font-size: rem(130px);
    //}
    //@include media-breakpoint-down(xs) {
    //  font-size: rem(50px);
    //  bottom: -0.5rem;
    //}
    span {
      position: relative;
      img {
        position: absolute;
        bottom: -6%;
        right: -8%;
        width:6vw;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
.home--about-section{
  padding: rem(75px 0 110px 0);
  background: right top/cover , no-repeat, #9f9f9f;
  position: relative;
  color:cl(white);
  font-size: rem(18px);
  line-height: 1.5;
  overflow: hidden;
  .welcome__slogan {
    z-index: 0;
    position: absolute;
    bottom: -1.9vw;
    left:0;
    width:100%;
    text-shadow: rem(0 9px 40px cl(black, .21));
    color:cl(white);
    text-align: center;
    margin: 0;
    font-size: 10.4vw;
    line-height: 1;
    font-family: $fontTitle;
    white-space: nowrap;
    text-transform: uppercase;
    //@include media-breakpoint-down(lg) {
    //  font-size: rem(150px);
    //}
    //@include media-breakpoint-down(md) {
    //  font-size: rem(130px);
    //}
    //@include media-breakpoint-down(xs) {
    //  font-size: rem(50px);
    //  bottom: -0.5rem;
    //}
    span {
      position: relative;
      img {
        position: absolute;
        bottom: -6%;
        right: -8%;
        width:6vw;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  h2{
    color: cl(white);
  }
  .btn{
    @include media-breakpoint-down(xs) {
      width: 100%;
    }
  }
  .about-block{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    .text-side{
      width: 37%;
      @include media-breakpoint-down(md) {
        width: 100%;
        padding-right: unset;
        order: 1;
      }
    }
    .image-side{
      width: 63%;
      @include media-breakpoint-down(md) {
        width: 100%;
        margin-bottom: 2rem;
        padding-right: unset;
      }
      .image-holder{
        width: 28.2vw;
        height: 24vw;
        position: relative;
        float: right;
        margin-right: 3.8vw;
        @include media-breakpoint-down(lg) {
          width: 40vw;
          height: 34vw;
        }
        @include media-breakpoint-down(md) {
          float: unset;
          margin: 0 auto;
          width: 90vw;
          height: 54vw;
        }
        .logo{
          position: absolute;
          z-index: 2;
          width: 36%;
          &-left{
            left: -8%;
            bottom: -1%;
          }
          &-right{
            right: -7%;
            bottom: 0;
          }
        }
        .photo{
          position: absolute;
          z-index: 1;
          background: center/cover , no-repeat, cl(primary);
          box-shadow: -1px 19px 62px 0px rgba(0, 0, 0, 0.34);
          &-1{
            width: 55.5%;
            height: 40%;
            top:0;
            left: 0;
          }
          &-2{
            top:0;
            right: 0;
            width: 40.7%;
            height: 56.52%;
          }
          &-3{
            bottom:0;
            width: 51.85%;
            height: 54.34%;
            left:3.7%;
          }
          &-4{
            bottom:0;
            width: 40.7%;
            right: 0;
            height: 39.13%;
          }
        }
      }
    }
  }
}

.home--news-events-section{
  padding: rem(63px 0 110px 0);
  background: center bottom/cover , no-repeat;
  position: relative;
  overflow: hidden;
  background-attachment: fixed;
  @include media-breakpoint-down(md) {
    background-size: 100% auto;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-attachment:scroll;
  }
  &:before{
    content: "";
    position: absolute;
    left: 0;
    height: 40%;
    width: 100%;
    top: 0;
    z-index: 0;
    background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 30%, rgba(255,255,255,0) 100%);
  }
  h4{
    @extend .ttu;
  }
  .sec-wrap{
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    @include media-breakpoint-down(md) {flex-wrap: wrap}
    .events-side{
      width: 26.5%;
      display: flex;
      flex-direction: column;
      @include media-breakpoint-down(md) {
        width: 100%;
        margin-bottom: 2rem;
      }
      .events-block{
        flex-grow: 1;
        display: flex;
        flex-direction: column;
      }
      .box{
        border-radius: rem(8px);
        background-color: rgb(255, 255, 255);
        box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
        width: 100%;
        flex-grow: 1;
        padding: rem(40px 25px 170px 25px);
        font-size: rem(16px);
        line-height: rem(26px);
        position: relative;
        margin-top: rem(20px);
        &:after{
          position: absolute;
          content: "";
          background-image: url("../img/event-decor.png");
          bottom: 0;
          width: 100%;
          left: 0;
          background-size: rem(160px 120px);
          background-position: center top;
          height: rem(150px);
          background-repeat: no-repeat;
        }
        .ttl{
          font-weight: 700;
          font-size: rem(20px);
          line-height: rem(30px);
          margin-bottom: rem(10px);
          color: cl(text);
        }
        .event-date{
          border-radius: rem(4px);
          background-color:cl(primary);
          min-width: rem(69px);
          height: rem(45px);
          @extend .ttu;
          position: absolute;
          left: rem(25px);
          top: rem(-14px);
          font-size: rem(16px);
          line-height: rem(26px);
          color: cl(white);
          display: flex;
          justify-content: center;
          align-items: flex-end;
          padding: rem(8px 15px);
          span{
            font-size: rem(30px);
          }
        }
        img{
          margin-top: rem(20px);
          width: 100%;
          max-height: rem(220px);
          object-fit: contain;
          object-position: center top;
        }
      }
      .btns-wrap{
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        margin-top: rem(14px);
        position: relative;
        box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
        //&:before{
        //  content:'';
        //  width: 1px;
        //  height: 100%;
        //  left: 49.7%;
        //  top:0;
        //  background-color: #b4afae;
        //  z-index: 10;
        //  position: absolute;
        //}
        .btn{
          width: 50%;
          &:first-child{
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            &:before,
            &:after{
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }
          }
          &:last-child{
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            &:before,
            &:after{
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
          }
        }
      }
    }
    .news-side{
      width: 63%;
      display: flex;
      flex-direction: column;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
      h4{
        text-align: right;
      }
      .btn{
        width: 100%;
        margin-top: rem(4px);
        box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
      }
      .news-block{
        flex-grow: 1;
        margin-top: rem(20px);
      }
      .news-item{
        border-radius: rem(8px);
        background-color: rgb(255, 255, 255);
        box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
        width: 100%;
        font-size: rem(16px);
        line-height: rem(26px);
        padding: rem(15px 30px);
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: rem(10px);
        min-height: rem(100px);
        @include media-breakpoint-down(sm) {
          flex-direction: column;
        }
        .news-item-inner{
          padding-right: rem(25px);
          max-height: rem(85px);
          overflow-y: hidden;
          @include media-breakpoint-down(sm) {
            order:1;
            padding-right: 0;
            max-height: unset;
          }
        }
        .ttl{
          font-weight: 700;
          font-size: rem(20px);
          line-height: rem(30px);
          margin-bottom: rem(16px);
          color: cl(text);
        }
        p{
          max-height: rem(30px);
          overflow-y: hidden;
        }
        .news-item-img{
          flex-shrink: 0;
          @include media-breakpoint-down(sm) {
            margin: 0 auto;
          }
        }
        img{
          object-fit: cover;
          width: rem(120px);
          height: rem(100px);
          object-position: center;

          @include media-breakpoint-down(sm) {
            margin: 0 auto 1rem;
            width: rem(240px);
            height: rem(200px);
          }
        }
        p{
          margin-bottom: 0;
        }
      }
    }
  }
  .events__slogan {
    position: absolute;
    bottom: 0.5vw;
    left:0;
    width:100%;
    text-shadow: rem(0 9px 40px cl(black, .21));
    color:cl(white);
    text-align: center;
    margin: 0;
    font-size: 10.4vw;
    line-height: 1;
    font-family: $fontTitle;
    white-space: nowrap;
    text-transform: uppercase;
    //@include media-breakpoint-down(lg) {
    //  font-size: rem(150px);
    //}
    //@include media-breakpoint-down(md) {
    //  font-size: rem(130px);
    //}
    //@include media-breakpoint-down(xs) {
    //  font-size: rem(50px);
    //  bottom: -0.5rem;
    //}
    span {
      position: relative;
      img {
        position: absolute;
        bottom: -6%;
        right: -8%;
        width:6vw;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

.home--video-album-section{
  padding: rem(80px 0 20px 0);
  background: left/cover , no-repeat, cl(white);
  position: relative;
  .welcome__slogan {
    position: absolute;
    bottom: -13vw;
    left:0;
    width:100%;
    text-shadow: rem(0 9px 40px cl(black, .21));
    color:cl(white);
    text-align: center;
    margin: 0;
    font-size: 10.4vw;
    line-height: 1;
    font-family: $fontTitle;
    white-space: nowrap;
    text-transform: uppercase;
    z-index: 5;
    //@include media-breakpoint-down(lg) {
    //  font-size: rem(150px);
    //}
    //@include media-breakpoint-down(md) {
    //  font-size: rem(130px);
    //}
    @include media-breakpoint-down(xs) {
      bottom: -16vw;
      font-size: 13vw;
    }
    span {
      position: relative;
      img {
        position: absolute;
        bottom: -6%;
        right: -8%;
        width:6vw;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .block-wrap{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
    }
    .video-side{
      width: 60.58%;
      position: relative;
      @include media-breakpoint-down(md) {
        width: 100%;
        margin-bottom: 4rem;
      }
      .video-holder{
        position: relative;
        width: 86.5%;
        margin-left: auto;
        padding-bottom: 50.5%;
        display: block;
        z-index: 8;
        background-color: cl(black);
        box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
        @include media-breakpoint-down(md) {
          margin: 0 auto;
          width: 100%;
          padding-bottom: 56.6%;
        }
        .poster-cover{
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          @extend .pos-center;
          z-index: 1;
        }
        video{
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
          position: absolute;
          @extend .pos-center;
          &[poster]{
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        iframe{
          width: 100%!important;
          height: 100%!important;
          z-index: 0;
          position: absolute;
          @extend .pos-center;
        }
        &.active{
          .poster-cover{
            display: none;
          }
          .play{
            display: none;
          }
          &:hover{
            cursor: pointer;
            .pause{
              display: block;
            }
          }
        }
        &:hover{
          cursor: pointer;
          .play{
            img {
              width: rem(78px);
              height: rem(78px);
              @include media-breakpoint-down(md) {
                width: rem(58px);
                height: rem(58px);
              }
            }
          }
        }
        .play{

          position: absolute;
          @extend .pos-center;
          z-index: 4;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          transition: 0.5s ease-in-out;
          transform-origin: center;
          display: flex;
          justify-content: center;
          align-items: center;

          img{
            width: rem(70px);
            height: rem(70px);
            position: relative;
            z-index: 4;
            opacity: 0.7;
            @include media-breakpoint-down(md) {
              width: rem(50px);
              height: rem(50px);
            }
          }
        }
      }
      .links-block{
        border-radius: rem(8px);
        background-color: cl(primary);
        box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
        position: absolute;
        width: rem(200px);
        min-height: rem(130px);
        z-index: 9;
        top: rem(-27px);
        left: 0;
        padding: rem(25px 25px);
        color: cl(white);
        @include media-breakpoint-down(md) {
          width: 93%;
          margin: 0 auto -1rem;
          height: auto;
          position: relative;
          top: unset;
          left: unset;

        }
        .main-link{
          font-size: rem(20px);
          color: cl(white);
          font-weight: bold;
          text-transform: uppercase;
          line-height: 1.25;
          margin-bottom: rem(20px);
          display: block;
        }
        .second-link{
          color: cl(white);
          font-size: rem(20px);
          font-weight: bold;
          line-height: 1.25;
          display: block;
        }
      }
    }
    .album-side{
      width: 29%;
      position: relative;
      @include media-breakpoint-down(md) {
        width: 100%;

      }
      .album-link{
        box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
        position: relative;
        display: block;
        width: 100%;
        padding-bottom: 105.5%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        font-size: rem(20px);
        font-weight: bold;
        line-height: 1.5;
        &:after{
          content: '';
          background-size: 100% auto;
          background-position: center bottom;
          background-repeat: no-repeat;
          background-image: url("../img/shadow.png");
          width: 100%;
          height: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
        }
        .name{
          color: rgb(255, 255, 255);
          text-align: center;
          position: absolute;
          @extend .pos-centerX;
          bottom: 1rem;
          width: 100%;
          z-index: 4;
        }
        .ttl{
          border-radius: rem(8px);
          background-color: cl(primary);
          color: cl(white);
          box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
          position: absolute;
          width: 93%;
          padding: rem(13px);
          top: rem(-27px);
          z-index: 3;
          @extend .pos-centerX;
          @extend .ttu;
          text-align: center;
        }
      }
    }
  }

}