/* banner start */
.banner {
  position: relative;
  background: center/cover no-repeat, cl(primary, 0.5);
  color: cl(white);
  margin-top: rem(75px);
  &:before{
    content: "";
    position: absolute;
    z-index: 1;
    background-color: cl(black,0.17);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  &--home {
    height: rem(740px);
    max-height: calc(100vh - 6.5rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include media-breakpoint-down(md) {
      height: rem(350px);
    }

  }
  &--page {
    height: rem(450px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    z-index: 1;
    padding: rem(30px 0 30px 0);
    background:left /cover no-repeat, cl(secondary);
    color: cl(white);
    margin-top: rem(70px);

  }
  &-video {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    z-index: 0;
    &.banner-video-absolute{
      position: absolute;
    }
    video {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-text {
    position: relative;
    width:100%;
    height: 100%;
    color:cl(white);
    z-index: 6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .page-ttl{
      font-size: rem(60px);
      line-height: 1.167;
      font-family: $fontTitle;
      color: rgb(255, 255, 255);
      text-transform: uppercase;
      text-align: center;
      max-width: rem(1000px);
      font-weight: 700;
      text-shadow: 8.51px 2.93px 8px rgba(0, 0, 0, 0.44);
      @include media-breakpoint-down(md) {
        font-size: rem(50px);
      }
      @include media-breakpoint-down(sm) {
        font-size: rem(40px);
      }
      @include media-breakpoint-down(xs) {
        font-size: rem(35px);
      }
    }
    .page-sub-ttl{
      font-size: rem(24px);
      line-height: 1.5;
      margin-top: 1rem;
      font-family: $font;
      color: rgb(255, 255, 255);
      text-align: center;
      max-width: rem(1000px);
      font-weight: 400;
      text-shadow: 8.51px 2.93px 8px rgba(0, 0, 0, 0.44);
      text-transform: none;
      @include media-breakpoint-down(sm) {
        font-size: rem(20px);
      }
      b{
        font-size: 700;
      }
    }
    p {
      margin: 0;
    }
  }

}
/* banner end */

