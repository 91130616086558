

/* **************** bootstrap ****************** */
@import "bootstrap/bootstrap";

/* **************** fontawesome ****************** */
@import "fontawesome/fontawesome";
@import "fontawesome/brands";
@import "fontawesome/regular";
@import "fontawesome/solid";

/* **************** INCLUDES ****************** */
@import "includes/rem";
@import "includes/base";
@import "includes/fonts";
@import "includes/variables";
@import "includes/common";

/* **************** loader ****************** */
@import "loader/loaders";

/* **************** plugins ****************** */
@import "plugins/slick";

/* **************** PAGE COMPONENTS ****************** */
@import "page-components/buttons";
@import "page-components/header";
@import "page-components/footer";
@import "page-components/banner";
@import "page-components/home";
@import "page-components/form";
@import "page-components/modal";
@import "page-components/static";
@import "page-components/cookie";
@import "page-components/error";
@import "page-components/inner";
@import "page-components/contact";
@import "page-components/gallery";
@import "page-components/news";
@import "page-components/faq";
@import "page-components/staff";

